<template>
  <div>
    <div class="theme-font-regular font-16" v-html="expandedVwTxt"></div>
    <b-row>
      <b-col lg="6" md="12">
        <vc-calendar
          ref="calendar"
          class="border-0 mywaste-calendar"
          :masks="{ weekdays: 'WWW' }"
          :attributes="calendarList"
          is-expanded
          @dayclick="calendarDayClicked"
        />
      </b-col>
      <b-col lg="6" md="12">
        <CalendarEventsList
          :calendar-events-list="createEventsList()"
          :limit="limit"
          :show-date="false"
          :show-error="false"
          v-if="selectedDate"
          :selected-date="selectedDate"
        ></CalendarEventsList>
        <p v-if="isNoEvents">No events on this date.</p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import moment from 'moment'
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'Calendar-one-column-grid',
  components: {
    CalendarEventsList: () => import('./CalendarEventsList.vue'),
  },
  data() {
    return {
      isDayClicked: false,
      eventsList: this.content.eventsResults,
      selectedDate: moment(new Date()).format('MM-DD-YYYY'),
      isNoEvents: false,
    }
  },
  props: {
    content: { type: Object },
    limit: [Number, String],
    expandedVwTxt: { type: String },
  },
  methods: {
    async calendarDayClicked(date) {
      this.isDayClicked = true
      const eventResults = cloneDeep(this.content.eventsResults)
      const selectedDate = moment(date.id).format('MM-DD-YYYY')
      this.selectedDate = selectedDate
      if (date) {
        if (eventResults.some((event) => moment(event.eventDate).format('MM-DD-YYYY') === selectedDate)) {
          if (!date.isMonth) {
            const calendar = this.$refs.calendar
            await calendar.move({ month: date.month, year: date.year })
          }
        }
      }
    },
    createEventsList() {
      const eventResults = cloneDeep(this.content.eventsResults)

      const today = new Date()

      const formatToday = moment(today).format('MM-DD-YYYY')

      let retEventResults = []
      if (this.isDayClicked) {
        retEventResults = eventResults.filter((event) => {
          return moment(event.eventDate).format('MM-DD-YYYY') === this.selectedDate ? event : null
        })
      } else {
        retEventResults = eventResults.filter((event) => {
          return moment(event.eventDate).format('MM-DD-YYYY') === formatToday ? event : null
        })
      }
      this.isNoEvents = !retEventResults.length
      return retEventResults
    },
  },
  computed: {
    calendarList() {
      return [
        {
          key: 'today',
          highlight: {
            class: 'vfc-today today',
          },
          dates: new Date(),
        },
        {
          key: 'selected',
          highlight: {
            class: 'selected',
          },
          dates: new Date(this.selectedDate),
        },
        {
          key: 'event',
          highlight: {
            class: 'scheduled',
            contentClass: 'container-scheduled',
          },
          dates: this.content.eventsResults.map((event) => {
            return moment(event.eventDate).format('MM-DD-YYYY')
          }),
        },
      ]
    },
  },
}
</script>
<style lang="scss" scoped>
.right-column {
  width: 40%;
}
.left-column {
  width: 55%;
}
</style>
